
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: "NotFound",
})
export default class NotFound extends Vue {
    private backIndex() {
        const routeUrl = this.$router.resolve({
            path: "/index",
        });
        window.open(routeUrl.href, '_top');
    }
}
